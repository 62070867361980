import React from "react";
import style from './LearnItem.module.css';

function LearnItem ({title}) {
  return (
    <li className={style.learnItem}>
      <span>
        {title}
      </span>
    </li>
  );
}

export default LearnItem;