import React, {useRef} from "react";
import Hero from "../../components/Hero/Hero";
import ForWhom from "../../components/ForWhom/ForWhom";
import Learn from "../../components/Learn/Learn";
import Included from "../../components/Included/Included";
import Reviews from "../../components/Reviews/Reviews";
import Cta from "../../components/Cta/Cta";
import About from "../../components/About/About";
import BreadCarousel from "../../components/BreadCarousel/BreadCarousel";
import Footer from "../../components/Footer/Footer";

function HomePage () {
  const ctaRef = useRef(null);
  const scrollToElement = () => {
    ctaRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      <Hero onMoreClick={scrollToElement}/>
      <ForWhom/>
      <Learn/>
      <Included/>
      <About/>
      <BreadCarousel/>
      <Cta ctaRef={ctaRef}/>
      <Reviews/>
      <Footer/>
    </>
  );
}

export default HomePage;