import React, { useEffect, useState } from "react";
import style from './ReviewsItem.module.css';
import { useTranslation } from "react-i18next";

function ReviewsItem ({data = {}}) {
  const [t] = useTranslation('common');
  const {author, content, short = "", imgList = [], hasMore = false} = data;
  const [reviewContent, setReviewContent] = useState();
  const [showMoreBtn, setShowMoreBtn] = useState(hasMore)
  const imgItems = imgList.map((src, index) => {
    const loadedSrc = require(`../../../assets/reviews/${src}`);
    return <img src={loadedSrc} alt='Review' key={`review-img-${index}`} />
  });

  useEffect(() => {
    const newContentValue = hasMore ? short : content;
    setReviewContent(newContentValue);
    setShowMoreBtn(hasMore);
  },[content, short, hasMore]);

  const handleMoreClick = () => {
    setReviewContent(content);
    setShowMoreBtn(false);
  }

  const moreBtn = hasMore ? <button onClick={handleMoreClick}>{t('reviews.linkText')}</button> : null;
  return (
    <div className={style.reviewsItem}>
      <p>
        {reviewContent}
        {showMoreBtn && moreBtn}
      </p>
      <span>
        {author}
      </span>
      <div>
        {imgItems.length > 0 && imgItems}
      </div>
    </div>
  );
}

export default ReviewsItem;