import React from "react";
import style from './Social.module.css';
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { Link } from "react-router-dom";

function Social({ className, small = false }) {
  const [t] = useTranslation('common');

  const classList = classNames(style.social, {
    [className]: className,
    [style.socialSmall]: small
  });

  return (
    <Link to={process.env.REACT_APP_INST_LINK} target="_blank" className={classList}>
      <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_1_4097" maskUnits="userSpaceOnUse" x="0" y="0" width="48" height="48">
          <path d="M0 3.8147e-06H48V48H0V3.8147e-06Z" fill="white" />
        </mask>
        <g mask="url(#mask0_1_4097)">
          <path d="M46.1241 24C46.1194 25.9731 46.0971 27.9462 46.0701 29.9192C46.0374 32.2996 46.0007 34.681 45.4037 37.0207C44.7777 39.4741 43.543 41.59 41.6221 43.1565C39.5979 44.8073 37.1512 45.6749 34.5104 45.8355C31.0079 46.0485 27.5039 46.1327 24 46.1245C20.496 46.1327 16.992 46.0485 13.4896 45.8355C10.8489 45.6749 8.40216 44.8073 6.37791 43.1565C4.45698 41.59 3.22229 39.4741 2.59623 37.0207C1.99923 34.681 1.96248 32.2996 1.92995 29.9192C1.90295 27.9462 1.88054 25.9731 1.87595 24C1.88054 22.027 1.90295 20.0538 1.92995 18.0808C1.96248 15.7004 1.99923 13.319 2.59623 10.9794C3.22229 8.52592 4.45698 6.40998 6.37791 4.84351C8.40216 3.19276 10.8489 2.32511 13.4896 2.16451C16.992 1.95151 20.496 1.86733 24 1.87558C27.5039 1.86733 31.0079 1.95151 34.5104 2.16451C37.1512 2.32511 39.5979 3.19276 41.6221 4.84351C43.543 6.40998 44.7777 8.52592 45.4037 10.9794C46.0007 13.319 46.0374 15.7004 46.0701 18.0808C46.0971 20.0538 46.1194 22.027 46.1241 24Z" stroke="#814624" strokeWidth="3" strokeMiterlimit="10" />
          <path d="M33.75 24C33.75 29.4366 29.3428 33.8438 23.9062 33.8438C18.4697 33.8438 14.0625 29.4366 14.0625 24C14.0625 18.5634 18.4697 14.1562 23.9062 14.1562C29.3428 14.1562 33.75 18.5634 33.75 24Z" stroke="#814624" strokeWidth="3" strokeMiterlimit="10" />
          <path d="M38.875 11.0938C38.875 12.4744 37.7557 13.5938 36.375 13.5938C34.9943 13.5938 33.875 12.4744 33.875 11.0938C33.875 9.71308 34.9943 8.59375 36.375 8.59375C37.7557 8.59375 38.875 9.71308 38.875 11.0938Z" fill="#814624" />
        </g>
      </svg>
      { !small && <span>
        {t('social')}
      </span>}
      
    </Link>
  );
}

export default Social;