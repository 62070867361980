import {createBrowserRouter} from "react-router-dom";
import React from "react";
import App from "./App";
import HomePage from "./pages/HomePage/HomePage";
import ApprovedPage from "./pages/ApprovedPage/ApprovedPage";
import DeclinedPage from "./pages/DeclinedPage/DeclinedPage";
import PolicyPage from "./pages/PolicyPage/PolicyPage";
import OfferPage from "./pages/OfferPage/OfferPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App/>,
    children: [
      {
        path: "/",
        index: true,
        element: <HomePage/>,
      },
      {
        path: "/declined",
        index: true,
        element: <DeclinedPage/>,
      },
      {
        path: "/approved",
        index: true,
        element: <ApprovedPage/>,
      },
      {
        path: "/policy",
        index: true,
        element: <PolicyPage/>,
      },
      {
        path: "/offer",
        index: true,
        element: <OfferPage/>,
      },
    ],
    //errorElement: <ErrorPage/>,
  },
]);

export default router;