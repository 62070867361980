import React from "react";
import style from './SystemAlert.module.css';
import Container from "../Container/Container";
import classNames from "classnames";

function SystemAlert({ children, className }) {
  const classList = classNames(
    style.systemAlert,
    {
      [className]: className,
    });
  return (
    <section className={classList}>
      <Container small column className={style.systemAlert__container}>
        {children}
      </Container>
    </section>
  );
}

export default SystemAlert;