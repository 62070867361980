import React from "react";
import style from './ButtonGroup.module.css';

function ButtonGroup ({children}) {
  
  return (
    <div className={style.bntGroup}>
      {children}
    </div>
  );
}

export default ButtonGroup;