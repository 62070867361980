import React from "react";
import SystemAlert from "../../components/SystemAlert/SystemAlert";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import { useTranslation } from "react-i18next";
import SectionDesc from "../../components/SectionDesc/SectionDesc";
import Button from "../../components/Button/Button";
import logo from "../../assets/logo.png";
import Header from "../../components/Header/Header";
import ButtonGroup from "../../components/ButtonGroup/ButtonGroup";
import { useSelector } from "react-redux";

function ApprovedPage() {
  const [t] = useTranslation('common');
  const currentLang = useSelector(state => state.user.currentLang);
  const translationsData = t('approved.desc', { returnObjects: true });
  const descContent = translationsData.map((text, index) => {
    return <span key={`desc-${index}`}>{text}</span>
  });
  const FILE_LINKS = {
    'uk': "Хліб на заквасці з легкістю.pdf",
    'en': "Sourdough bread. Easy steps.pdf",
    'ru': "Хлеб на закваске с лёгкостью.pdf"
  };
  const fileLink = require(`../../assets/book/${FILE_LINKS[currentLang]}`);
  const CHAT_LINKS = {
    'uk': process.env.REACT_APP_APPROVED_LINK_UK,
    'en': process.env.REACT_APP_APPROVED_LINK_EN,
    'ru': process.env.REACT_APP_APPROVED_LINK_RU
  }
  return (
    <>
      <Header />
      <SystemAlert>
        <img src={logo} alt="lovenbread logo" />
        <SectionTitle center tag="h1">
          {t('approved.title')}
        </SectionTitle>
        <SectionDesc center lastBold>
          {descContent}
        </SectionDesc>
        <ButtonGroup>
          <Button to={CHAT_LINKS[currentLang]} simple>
            {t('approved.chatLink')}
          </Button>
          <Button to={fileLink} download={FILE_LINKS[currentLang]}>
            {t('approved.fileLink')}
          </Button>
        </ButtonGroup>
      </SystemAlert>
    </>
  );
}

export default ApprovedPage;