import React from "react";
import style from './Header.module.css';
import Container from "../Container/Container";
import LangSwitcher from "../LangSwitcher/LangSwitcher";
import Social from "../Social/Social";

function Header() {
  return (
    <header className={style.header}>
      <Container small className={style.header__container}>
        <LangSwitcher />
        <Social small />
      </Container>
    </header>
  );
}

export default Header;