import React from "react";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import logo from "../../assets/logo.png";
import Rules from "../../components/Rules/Rules";

function OfferPage() {
  return (
    <>
      <Rules>
        <img src={logo} alt="lovenbread logo" />
        <SectionTitle center tag="h1">
          Договір публічної оферти
        </SectionTitle>
        <p>
          Даний документ, положення якого викладені нижче по тексту, є публічною пропозицією (офертою) укладення публічного договору між Інтернет-магазином, сайт якого розміщений в мережі Інтернет за адресою https://lovenbread.com.ua/ і будь-якою фізичною або юридичною особою на однакових для всіх умовах, та яким визначаються порядок і умови надання послуг, купівлі-продажу товарів через сайт Інтернет-магазину.
        </p>
        <h2>
          ОСНОВНІ ТЕРМІНИ ТА ВИЗНАЧЕННЯ, ЩО ВИКОРИСТОВУЮТЬСЯ В ДАНОМУ ДОГОВОРІ МАЮТЬ НАСТУПНЕ ЗНАЧЕННЯ:
        </h2>
        <p>
          Публічна оферта – публічна пропозиція Виконавця, адресована невизначеному колу Замовників, згідно зі ст. 641 Цивільного кодексу України, укласти договір купівлі-продажу товарів та послуг в Інтернет-магазині, розміщено в мережі Інтернет за мережевою адресою: https://lovenbread.com.ua/ на визначених нижче умовах;
        </p>
        <p>
          Публічний договір – укладений між Виконавцем і Замовником договір, за яким Виконавець взяв на себе обов’язок здійснювати продаж товарів, виконання робіт або надання послуг Замовнику на однакових для всіх умовах з моменту Акцепту Замовником його умов;
        </p>
        <p>
          Акцепт – повне, безумовне і беззастережне прийняття Замовником умов Оферти Договору, шляхом передоплати замовлених Послуг та/або шляхом здійснення дій по реєстрації на сайті Виконавця;
        </p>
        <p>
          Виконавець – ФОП Тацюн І.В., що зареєстрована як суб’єкт господарювання за законодавством України;
        </p>
        <p>
          Замовник – будь-яка особа, яка акцептувала умови даного Договору з власного інтересу або виступає від імені і в інтересах представленої нею особи, та яка користується послугами Виконавця;
        </p>
        <p>
          Інтернет-магазин – засіб для представлення або реалізації товару, роботи чи послуги шляхом вчинення електронного публічного правочину;
        </p>
        <p>
          Сайт виконавця – офіційна веб-сторінка Виконавця в мережі Інтернет, що за наступною адресою: https://lovenbread.com.ua/ і яка є одним із основних джерел інформування Замовника про Виконавця, послуги, що ним надаються, а також їх вартість;
        </p>
        <p>
          Послуга та/або Товар – перелік найменувань асортименту, представлений Виконавцем в Інтеренет-магазині на офіційному сайті в мережі Інтернет;
        </p>
        <p>
          Захід – це Продукт (курс, тренінг, програма, майстер-класс, тощо), де Послуга надається у форматі відео-уроків та додаткових матеріалів в односторонньому порядку Виконавцем та/або третьою особою, залученою Виконавцем, інформація про який розміщується на веб-сайті Виконавця;
        </p>

        <h2>
          1. ЗАГАЛЬНІ ПОЛОЖЕННЯ
        </h2>
        <p>
          1.1. Даний Договір є публічним договором (надалі – Договір) відповідно до ст.ст. 633, 634, 641 Цивільного кодексу України, Закону України «Про електронну комерцію», а особа, що здійснює акцепт даної оферти стає Замовником відповідно до ст. 642 Цивільного кодексу України, ст. 11 Закону України «Про електронну комерцію».
        </p>
        <p>
          1.2. Даний Договір, у відповідності до ст. 628 Цивільного кодексу України є змішаним договором, тобто таким, в якому містяться елементи різних договорів: публічного договору, договору приєднання, договору надання послуг, роздрібної купівлі-продажу тощо.
        </p>
        <p>
          1.3. Даний Договір, у відповідності до ст. 634 Цивільного кодексу України є також договором приєднання, оскільки його умови визначені Виконавцем та який може бути укладений лише шляхом приєднання Замовника до запропонованого Договору в цілому та без застережень, крім випадків прямо передбачених законом.
        </p>
        <p>
          1.4. Вчинення Замовником дій, що засвідчують його бажання укласти Договір, під якими у тексті цього Договору розуміється оплата послуг Виконавця згідно умов цього Договору, підтверджує факт повного і безумовного прийняття (акцепт) Замовником умов цього Договору, без будь-яких зауважень, відповідно до ст. 642 Цивільного кодексу України.
        </p>
        <p>
          1.5. Акцептуючи даний Договір, Замовник підтверджує, що до укладення цього Договору він був повністю та належним чином ознайомлений з усіма його положеннями та змістом, ціною Договору, розміщеними на офіційному сайті Виконавця в Інтернет-магазині в мережі Інтернет за адресою: https://lovenbread.com.ua/, прийняв їх за власної волі і без будь-якого примусу.
        </p>
        <p>
          1.6. Кожна Сторона гарантує іншій Стороні, що володіє усіма правами та повноваженнями, що є необхідними та достатніми для укладення і виконання Договору у відповідності до його умов (необхідна дієздатність Сторін). Всі ризики та відповідальність із визнання цього Договору неукладеним покладаються на Замовника.
        </p>
        <p>
          1.7. Договір, укладений з Виконавцем зо допомогою акцепту публічної оферти, має юридичну чинність у відповідності до ст. 642 ЦК України і є рівноважним договору, підписаному Сторонами та вважається таким, що Замовник ознайомився і згоден з умовами цієї Оферти. Цей Договір прирівнюється до укладення Сторонами двостороннього письмового договору на умовах, що викладені в цьому Договорі і всі передбачені цією Офертою умови, – є обов’язковими для Сторін.
        </p>
        <p>
          1.8. Чинна редакція положень Договору, що передбачені цією Офертою, міститься на офіційному сайті Інтернет-магазину Виконавця за посиланням https://lovenbread.com.ua/. Виконавець залишає за собою право змінювати чи доповнювати положення Договору, що передбачені цією Офертою, в будь-який момент без попереднього чи наступного повідомлення Замовника про такі зміни та/або доповнення. Замовник самостійно відстежує зміни до положень Договору, які передбачено цією Офертою, і знайомиться з їх чинною редакцією. Продовження користування послугами після внесення змін чи доповнень до положень Договору, що передбачені цією Офертою, означає прийняття та згоду Замовника з такими змінами чи доповненнями.
        </p>

        <h2>
          2. АКЦЕПТУВАННЯ ДОГОВОРУ
        </h2>
        <p>
          2.1. Ця оферта, у випадку прийняття її Замовником, вважається укладеним письмовим, у тому числі електронним, правочином (договором) на умовах приєднання, до якого приєднується Замовник, з моменту Акцепту останнім умов Договору, що передбачені цією Офертою, разом з умовами або додатками до неї, у випадку наявності таких додатків.
        </p>
        <p>
          2.2. Для того, щоб прийняти цю Оферту і укласти Договір, Замовник повинен здійснити акцепт, тобто вчинити дії щодо надання згоди на укладання Договору на умовах, викладених в Оферті.
        </p>
        <p>
          2.3. Пропозиція є повно й беззастережно прийнятою (акцептованою), а договір є укладеним з моменту оплати Замовником Послуг (Продуктів) Виконавця, та/або шляхом здійснення дій по реєстрації на сайті, в залежності від того, яка подія настала першою. В такому разі, додаткове підписання Договору з боку Замовника не потребується. При цьому натискання Замовником кнопки «Зареєструватися» означає повну та беззастережну згоду Замовника з положеннями Договору, що передбачені цією Офертою (акцепт оферти). У випадку незгоди з умовами Договору, що передбачені цією Офертою, або з її окремими положеннями Замовник зобов’язується припинити перегляд та будь-яке використання Послуг (Продуктів) Виконавця.
        </p>
        <p>
          2.4. Факт оплати замовлених Послуг та/або здійснення дій по реєстрації на сайті, з метою акцепту даної Оферти, зокрема, але не виключно, означає, що Замовник ознайомлений з положеннями Договору та зобов’язується їх дотримуватись, а у разі їх порушення або невиконання – згоден нести відповідальність згідно всіх пунктів, визначених цим Договором та чинним законодавством України.
        </p>
        <p>
          2.5. Акцептуючи Оферту, Замовник автоматично погоджується з повним і беззаперечним прийняттям умов Прейскуранта і всіх додатків, у випадку наявності таких додатків, які є невід’ємною частиною цього Договору.
        </p>

        <h2>
          3. ПРЕДМЕТ ДОГОВОРУ
        </h2>
        <p>
          3.1. За цим Договором Виконавець зобов’язується забезпечити проходження навчання Замовником шляхом надання йому Послуг (Продуктів), в тому числі, які містять об’єкти інтелектуальної власності Виконавця (авторські права на твори, знаки для товарів і послуг, ноу-хау, комерційні таємниці, дизайн, промислові зразки, комерційні (фірмові) найменування тощо), а Замовник зобов’язується своєчасно оплатити та прийняти такі послуги.
        </p>
        <p>
          3.2. Актуальні умови, розклад, програма, вартість та дати проведення Заходів, оприлюднюються міститься на офіційному сайті Інтернет-магазину Виконавця за посиланням https://lovenbread.com.ua/ та/або надсилаються на адресу електронної пошти Замовника. Виконавець має право змінювати розклад, програму, вартість та дату проведення Заходів без згоди Замовника, завчасно повідомивши про це Замовника, до зміни. Замовник вважається належно повідомленим, якщо повідомлення оприлюднено за адресами в домені https://lovenbread.com.ua/ та/або надіслано Замовнику на електронну пошту.
        </p>
        <p>
          3.3. Жодних претензій за результативністю застосування Замовником знань та навичок, які було отримано в результаті отримання Послуг (Продуктів), не може бути пред’явлено на адресу Виконавця. Відповідальність за використання цих знань та навичок, а також за будь-які результати, прямі або побічні ефекти, які було отримано у результаті використання цих знань та навичок, цілком та повністю покладається на Замовника.
        </p>

        <h2>
          4. ПОРЯДОК НАДАННЯ ТА ОТРИМАННЯ ПОСЛУГ (ПРОДУКТУ)
        </h2>
        <p>
          4.1. Замовником послуг і стороною цього Договору можуть бути фізичні особи, що мають діє- і правоздатність. Заповнення заявки на Сайті та оплатою послуг є фактом підтвердження, що Замовник має повну дієздатність.
        </p>
        <p>
          4.2. Перелік, назва і зміст Послуг (Продуктів), а також терміни їх надання визначаються на відповідних веб-сторінках Сайту. Умови та програма цих Заходів публікуються Виконавцем на сайті https://lovenbread.com.ua/
        </p>
        <p>
          4.3. Виконавець надає Замовнику послуги шляхом організації та проведення Заходів, а Замовник зобов’язується отримувати надані йому послуги і оплачувати їх на умовах, визначених цим Договором.
        </p>
        <p>
          4.4. Заходи можуть проводиться як Виконавцем особисто, так і іншими особами, що залучаються Виконавцем за домовленістю.
        </p>
        <p>
          4.5. Виконавець залишає за собою право в будь-який момент змінювати умови, порядок надання та отримання Послуги (Продуктів) в односторонньому порядку без попереднього повідомлення Замовника, публікуючи зазначені зміни на Сайті, не пізніше 3 (трьох) робочих днів з дня їх внесення (прийняття). Зміни вступають в силу з моменту опублікування нового тексту на сайті, якщо інша (пізніша) дата вступу в силу змін, не зазначена Виконавцем при обнародуванні інформації про зміни. Виконавець рекомендує Замовникові регулярно перевіряти умови, порядок надання та отримання Послуги (Продуктів) на предмет їх змін та/ або доповнень.
        </p>
        <p>
          4.6. Інформація про конкретні Послуги (Продукти) та/або зміст конкретних Послуг (Продуктів), інформація про умови надання доступу до Послуг (Продуктів), специфікація вимог для доступу до Послуги (Продукту), інші відомості або вимоги, які повинні та/або можуть бути повідомлені Замовнику відповідно до цього Договору або вимог законодавства, вважаються повідомленими Замовнику належним чином, якщо виконана хоча б одна з наступних умов:
        </p>
        <p>
          4.6.1. Опубліковані на Сайті Виконавця.
        </p>
        <p>
          4.6.2. Доведені до відома Замовника за допомогою електронних повідомлень, спрямованих Замовникові Виконавцем по мережі Інтернет.
        </p>
        <p>
          4.6.3. Надруковані в рекламних та інформаційних матеріалах Виконавця.
        </p>
        <p>
          4.6.4. Повідомлені Замовнику при його зверненні за контактними адресами і телефонами Виконавця.
        </p>
        <p>
          4.6.5. Доведені до відома Замовника іншими способами, доступними Виконавцю, в тому числі через засоби масової інформації (рекламу).
        </p>
        <p>
          4.7. Будь-яка підписка/відмова від конкретних послуг Замовником має рівнозначну юридичну силу, якби Сторони уклали відповідну додаткову угоду в письмовій формі на паперовому носії.
        </p>
        <p>
          4.8. Замовник зобов’язується не відтворювати, не повторювати, не копіювати, не продавати, а також не використовувати в будь-яких інших цілях інформацію і матеріали, що стали йому доступними в зв’язку з наданням Послуг (Продуктів), за винятком їх особистого користування.
        </p>
        <p>
          4.9. Замовник зобов’язується не здійснювати відео- та/або аудіо- запис Заходу без спеціального на те дозволу Виконавця.
        </p>
        <p>
          4.10. Замовнику забороняється:
        </p>
        <p>
          поширювати відео, аудіо-записи Заходу будь-якими можливими способами;
        </p>
        <p>
          поширювати матеріали Заходу у вигляді розшифровки, тобто перекладу аудіо або відео матеріалів в текстовий формат, і перекладу на інші мови;
        </p>
        <p>
          використовувати отриману від Виконавця інформацію в комерційних цілях, наприклад, для самостійного проведення Заходів для третіх осіб за матеріалами, отриманими від Виконавця;
        </p>
        <p>
          допускати поширення недостовірної, неправдивої інформації, інформації, яка ганьбить честь, гідність, ділову репутацію Виконавця.
        </p>

        <h2>
          5. ВАРТІСТЬ ПОСЛУГ ТА ПОРЯДОК ЗДІЙСНЕННЯ ОПЛАТИ
        </h2>
        <p>
          5.1. Вартість Послуг за цією Офертою/Договором визначається відповідно до вартості кожного конкретної Послуги (Товару) на дату його замовлення (бронювання) та/або діючих тарифів та/або інших умов оплати, які публікуються на офіційному сайті Інтернет-магазину Виконавця за посиланням https://lovenbread.com.ua/ та/або надсилаються на адресу електронної пошти Замовника. Ціни на послуги та товари вказуються в офіційній валюті країн Європейського Союзу (Євро).
        </p>
        <p>
          5.2. Послуги (Товари), інформація про які розміщена на Сайті Виконавця, надаються Замовникові за умови 100 % передоплати, шляхом перерахування грошових коштів на визначений Виконавцем рахунок. Виконавець приступає до виконання своїх зобов’язань за цим Договором лише після отримання оплати за замовленими Послугами (Товарами), тобто фактичного зарахування коштів.
        </p>
        <p>
          5.3. Оплачені, але невикористані у встановлені терміни Послуги, вважаються наданими та вартість їх не повертається.
        </p>
        <p>
          5.4. У разі несплати послуг Замовником в порядку, встановленому цим розділом, у Виконавця не виникає зобов’язання з надання Замовнику Послуг (Продуктів).
        </p>
        <p>
          5.5. Вартість послуг по Договору може бути змінена Виконавцем в односторонньому порядку шляхом оприлюднення нових тарифів на сайті. При цьому заброньовані та оплачені Замовником Послуги, надаються за тарифами, що діяли на момент такого бронювання.
        </p>
        <p>
          5.6. При замовленні Послуг (Товарів), Замовник передає Виконавцю інформацію, необхідному для повного і якісного надання Послуг Виконавцем (П.І.Б., номер власного телефону, адресу електронної пошти, тощо). Замовник самостійно несе відповідальність за правильність здійснених ним платежів.
        </p>

        <h2>
          6. ПРАВА ТА ОБОВ’ЯЗКИ СТОРІН
        </h2>
        <p>
          6.1. Виконавець зобов’язаний:
        </p>
        <p>
          6.1.1. Надавати Послуги (Продукти) Замовнику відповідно до обраного ним Заходу, в порядку і строки, передбачені Програмами і тарифами Виконавця, які оприлюднені за офіційному веб-сайті Виконавця за адресою https://lovenbread.com.ua/. Виконавець має право залучати для надання Послуг (Продуктів) найманих працівників та/або субвиконавців (субпідрядників), та/або повірених, та/або інших уповноважених осіб Виконавця. При цьому Виконавець залишається відповідальним перед Замовником за належну якість надання Послуг, такими найманими працівниками та/або субвиконавцями (субпідрядниками), та/або повіреними, та/або іншими уповноваженими особами Виконавця
        </p>
        <p>
          6.1.2. Надавати Замовнику повну та достовірну інформацію про умови та порядок надання Послуг (Продуктів) Виконавцем.
        </p>
        <p>
          6.1.3. На прохання Замовника роз’яснити йому зміст цього Договору та будь-якої з його умов.
        </p>
        <p>
          6.1.4. Не допускати розголошення персональних даних Замовника, а також іншої інформації, яка стосується особистих даних Замовника і стала відома Виконавцю у зв’язку із виконанням даного Договору.
        </p>
        <p>
          6.1.5. У випадку, якщо Програма (Захід) передбачає видачу сертифікату/свідоцтва або іншого аналогічного документа про проходження навчання чи підтвердження/ підвищення кваліфікації, то Виконавець надсилає (або надає особисто в руки) сертифікат/свідоцтво про проходження Програми (Заходу) Замовнику протягом 10 робочих днів після його завершення в паперовому вигляді. На вимогу Замовника сертифікат/ свідоцтво може бути надіслано додатково в електронній формі.
        </p>
        <p>
          6.1.6. Неухильно дотримуватись положень Договору, які передбачено цією Офертою.
        </p>
        <p>
          6.1.7. У разі необхідності, забезпечити Замовника матеріалами, необхідними для участі в Заході на умовах Договору.
        </p>
        <p>
          6.2. Виконавець має право:
        </p>
        <p>
          6.2.1. Вимагати від Замовника сплати вартості обраних Послуг (Продуктів) відповідно до умов Договору.
        </p>
        <p>
          6.2.3. Відмовити у наданні Послуг (Продуктів) або відмовитись від Договору у зв’язку з неналежним виконанням Замовником своїх зобов’язань, передбачених даним Договором, грубого або систематичного (більш ніж 2 рази) порушення правил поведінки, або порушення порядку отримання послуг (товарів) Замовником. При цьому вартість сплачених послуг не повертаються Замовнику і є штрафом за порушення умов цього Договору.
        </p>
        <p>
          6.2.4. На власний розсуд відмовити Замовнику в укладенні Договору або ініціювати припинення надання Послуг (Продуктів).
        </p>
        <p>
          6.2.5. Вимагати від Замовника відшкодування завданих ним збитків та нанесеної шкоди (матеріальні збитки та моральна шкода) на користь Виконавця, якщо не доведено, що шкода завдана не з вини Замовника.
        </p>
        <p>
          6.2.6. Розвивати (розширювати) комплекс Послуг (Продуктів).
        </p>
        <p>
          6.2.7. Обробляти персональні дані Замовника під час укладення та виконання Договору відповідно до Закону України «Про захист персональних даних» на підставі угоди Замовника під час акцептування Публічної оферти. Виконавець має право використовувати дані Замовника, надані останнім, будь-яким способом, що не суперечить чинному законодавству України
        </p>
        <p>
          6.2.8. Відмовити в обслуговуванні Замовника в разі повернення Попередньої оплати в порядку і на умовах, передбачених Договором.
        </p>
        <p>
          6.2.9. Залучати третіх осіб до надання Послуг (Продуктів) Замовнику, без узгодження таких дій Виконавця з Замовником.
        </p>
        <p>
          6.2.10. Виконавець залишає за собою право в будь-який момент змінювати умови цієї Оферти, Прейскурант (тарифи на Послуги) в односторонньому порядку без попереднього повідомлення Замовника, публікуючи зазначені зміни на Сайті, не пізніше 3 робочих днів з дня їх внесення (прийняття). Зміни вступають в силу з моменту опублікування нового тексту на сайті, якщо інша (пізніша) дата вступу в силу змін, не зазначена Виконавцем при обнародуванні інформації про зміни. Виконавець рекомендує Замовникові регулярно перевіряти умови цієї Оферти на предмет їх змін та/або доповнень. Продовження використання Сайту Замовником після внесення Виконавцем змін та/або доповнень до Оферти означає безумовне і повне прийняття і згоду Замовника з такими змінами і доповненнями.
        </p>
        <p>
          6.2.11. Скасовувати, переривати або переносити будь-які Заходи, змінювати їх матеріали.
        </p>
        <p>
          6.2.12. Публікувати будь-які матеріали та відгуки, створені Замовниками в процесі проходження Заходів, без жодних обмежень або компенсацій Замовнику.
        </p>
        <p>
          6.2.13. Здійснювати розсилки повідомлень Замовнику у будь-якій формі та способом, що містять організаційно-технічну, рекламну чи іншу інформацію про можливості Сайту, Заходів, тощо.
        </p>
        <p>
          6.2.14. Виконавець має право консультувати Замовника під час оформлення/ підтвердження/оплати Послуг, у тому числі самостійно зв’язуватися з Замовником за вказаною Замовником при реєстрації на Сайті адресою електронної пошти/номером телефону.
        </p>
        <p>
          6.3. Замовник зобов’язаний:
        </p>
        <p>
          6.3.1. Здійснювати своєчасну та повну оплату наданої Послуги (Продукту) на користь Виконавця, в порядку та на умовах, визначених на офіційному веб-сайті Виконавця, що за адресою https://lovenbread.com.ua/.
        </p>
        <p>
          6.3.2. Неухильно дотримуватись положень Договору, які передбачено цією Офертою.
        </p>
        <p>
          6.3.3. Охайно та дбайливо ставитись до обладнання, навчальних матеріалів, додаткового інвентарю та іншого майна, яке знаходиться в Приміщенні Виконавця.
        </p>
        <p>
          6.3.4. Нести відповідальність (матеріальну та/або іншу юридичну) за свої дії під час отримання Послуг (Товарів) та за шкоду, завдану майну третіх осіб Замовником.
        </p>
        <p>
          6.3.5. Повідомити Виконавцю достовірні дані про себе.
        </p>
        <p>
          6.3.6. Дотримуватися розкладу/графіку Заходів, виконувати практичні, самостійні завдання, використовувати отримані навчальні та методичні матеріали виключно в особистих цілях, не передавати отримані навчальні та методичні матеріали третім особам.
        </p>
        <p>
          6.3.7. Замовник надає згоду на отримання інформаційних розсилок від Виконавця.
        </p>
        <p>
          6.3.8. Утримуватися в процесі навчання від дій, які:
        </p>
        <p>
          Можуть перешкоджати іншим Замовникам, запрошеним особам або лекторам приймати участь у Заході і отримувати інформацію в процесі проведення навчання.
        </p>
        <p>
          Можуть завдати шкоди матеріальним цінностям інших Замовників, запрошених осіб або Виконавцю, власників або користувачів приміщення, в якому проводиться Захід.
        </p>
        <p>
          Порушують громадський порядок, в тому числі ображають присутніх, виражаються у використанні нецензурних виразів та/або гучних розмов, створенні шуму за допомогою пристроїв і т.д.
        </p>
        <p>
          Принижують честь, гідність, ділову репутацію Виконавця, інших Замовників, запрошених осіб, в тому числі шляхом розміщення інформації в мережі Інтернет, друкованих виданнях, розсилках або у інший спосіб, що має публічний характер.
        </p>
        <p>
          Мають прояв у використанні аудіо- або відео-апаратури з метою копіювання матеріалів Заходу або без такої мети.
        </p>
        <p>
          6.4. Замовник має право:
        </p>
        <p>
          6.4.1. Самостійно вибирати цікаві для нього Послуги (Товари).
        </p>
        <p>
          6.4.2. Отримати Послуги (Товари) Виконавця в порядку, строки та на умовах, передбачених цим Договором.
        </p>
        <p>
          6.4.3. Отримувати від Виконавця повну та достовірну інформацію про умови та порядок надання Послуг (Продуктів).
        </p>
        <p>
          6.4.4. Отримувати від Виконавця роз’яснення про зміст цього Договору та будь-якої з його умов.
        </p>
        <p>
          6.4.5. Вимагати належного ставлення та поваги до себе з боку Виконавця та його представників.
        </p>
        <p>
          6.4.6. Замовляти додаткові Послуги (Продукти), що підлягають додатковій оплаті.
        </p>
        <p>
          6.4.7. Вимагати від Виконавця дотримання усіх положень Договору, які передбачено цією Офертою.
        </p>
        <p>
          6.4.8. Отримувати будь-яку інформацію щодо діяльності Виконавця, яка не вважається Виконавцем конфіденційною.
        </p>
        <p>
          6.4.9. Замовник має право відмовитися від укладеного Договору або ініціювати його розірвання, завчасно попередивши про це виконавця не пізніше ніж за 3 (три) дні до початку отримання Послуги. У випадку відмови від укладеного Договору або ініціювання його розірвання з порушенням 3-денного строку на попередження, Виконавець не зобов’язаний повертати Замовнику сплачені кошти. Внесена Замовником сума в такому випадку буде оплатою фактичної вартості послуг на дату розірвання Договору. Виконавець має право, за його згодою, але не зобов’язаний, повернути частину коштів Замовнику або зарахувати їх у рахунок оплати наступних Заходів або інших Послуг (Продуктів), які замовив або замовить Замовник. У будь-якому випадку сума такого повернення або зарахування не може бути більшою за 50% вартості оплачених Замовником Послуг (Продуктів) щодо яких Замовник ініціював відмову від укладеного за цією Офертою Договору або ініціював його розірвання з порушенням 3-денного строку на попередження.
        </p>

        <h2>
          7. ПЕРСОНАЛЬНІ ДАНІ ТА УМОВИ КОНФІДЕНЦІЙНОСТІ
        </h2>
        <p>
          7.1. Беручи до уваги те, що при укладанні цього Договору Сторонам стають відомі персональні дані фізичних осіб, з метою дотримання положень Закону України «Про захист персональних даних» фізичні особи, підписуючи даний договір:
        </p>
        <p>
          підтверджують, що дали згоду на збір, обробку, накопичення, зберігання та поширення персональних даних Сторін даного Договору;
        </p>
        <p>
          підтверджують що проінформовані про свої права, визначені Законом України «Про захист персональних даних» і про мету збору персональних даних;
        </p>
        <p>
          повністю розуміють, що надана інформація є персональними даними, тобто даними, що використовуються для ідентифікації представникі Сторін і погоджується з тим, що такі дані обробляються (зберігаються) у Сторін для подальшого використання відповідно до Господарського, Цивільного, Податкового кодексів України, Закону України «Про бухгалтерський облік та фінансову звітність» та для реалізації ділових відносин між Сторонами.
        </p>
        <p>
          7.2. Персональні дані Замовника зберігаються і обробляються Виконавцем в порядку, передбаченому цим Договором, протягом усього терміну дії Договору та протягом 3 (трьох) років з моменту його розірвання.
        </p>
        <p>
          7.3. Сайт використовує файли «cookie» та інші технології, щоб здійснювати контроль за використанням послуг Виконавця. Ці дані необхідні для оптимізації технічної роботи Сайту і підвищення якості надання послуг. На Сайті автоматично записуються відомості (включаючи URL, IP-адреса, тип браузера, мова, дата і час запиту) про кожного відвідувача Сайту, а також факти використання програмного забезпечення вебінару. Компанія може об’єднувати Персональні дані, отримані від Замовника, з відомостями (в тому числі демографічними, географічними, освітніми, трудовими, інформацію про кількість візитів на Сайт, загальний час перебування на Сайті), отриманими з інших сервісів на веб-сайтах Виконавця або з незалежних джерел, щоб поліпшити якість надання послуг. Замовник може відмовитися від надання персональних даних при відвідуванні Сайту або відключити файли «cookie», але в цьому випадку не всі функції і сервіси в складі Сайту можуть працювати правильно.
        </p>
        <p>
          7.4. Передбачені цим Договором умови конфіденційності поширюються на всю інформацію, яку Виконавець може отримати про Замовника під час перебування останнього на Сайті і використання Сайту. Виконавець вважає інформацію про факти використання Послуг (Продуктів) Замовником, так само як і зміст Контенту Заходу конфіденційною інформацією.
        </p>
        <p>
          7.5. Не є конфіденційною інформація, публічно розкрита Сторонами в ході виконання цього Договору, а також інформація, яка може бути отримана Сторонами або третіми особами з джерел, до яких є вільний доступ будь-яким особам.
        </p>
        <p>
          7.6. Виконавець вживає всіх необхідних заходів для захисту конфіденційності персональних даних Замовників від несанкціонованого доступу, зміни, розкриття чи знищення, в тому числі:
        </p>
        <p>
          забезпечує постійну внутрішню перевірку процесів збору, зберігання і обробки даних і забезпечення безпеки;
        </p>
        <p>
          забезпечує фізичну безпеку даних, запобігаючи неправомірний доступ до технічних систем, які забезпечують роботу Сайту, в яких Виконавець зберігає персональні дані;
        </p>
        <p>
          надає доступ до персональних даних лише тим співробітникам Виконавця або уповноваженим особам, яким ця інформація необхідна для виконання обов’язків, безпосередньо пов’язаних з наданням послуг Замовникові, а також експлуатації, розробки і поліпшення Сайту. Такі особи пов’язані з Виконавцем зобов’язаннями щодо дотримання конфіденційності;
        </p>
        <p>
          Виконавець обробляє персональні дані, в тому числі, що передаються в сеансах реєстрації/авторизації на Сайті і сесіях використання інших сервісів Сайту на серверах провайдерів послуг зв’язку, що діють відповідно до законодавства України та інших країн.
        </p>
        <p>
          7.7. Виконавець ніколи не вимагає надання Замовником будь-якого номера платіжної карти або пін-коду для кредитних або інших грошових карт. У разі появи таких запитів (на Сайті або у вигляді електронних повідомлень), Користувачеві слід негайно припинити використання Сайту і повідомити про це на контактні адреси Виконавця, зазначені в цьому Договорі.
        </p>

        <h2>
          8. ПРАВА НА ІНТЕЛЕКТУАЛЬНУ ВЛАСНІСТЬ ТА КОРИСТУВАННЯ КОНТЕНТОМ
        </h2>
        <p>
          8.1. На всі назви брендів та товарні знаки, що згадуються на веб-сайті Виконавця, включаючи назви брендів та товарних знаків, що належать третім особам, поширюються положення чинного законодавства України, чинних міжнародних договорів, згода на обов’язковість яких надана Верховною Радою України, що регулюють дані правовідносини. Згадка про назви та бренди згаданих товарів на веб-сайті та/або у матеріалах Виконавцем, не є ознакою, що вони не захищені правами третіх осіб.
        </p>
        <p>
          8.2. Всі матеріали, розміщені на офіційному веб-сайті Виконавця, що за посиланням https://lovenbread.com.ua/, у тому числі і всі матеріали, які надаються Замовнику у зв’язку із виниклими за цим Договором правовідносинами (платного контенту), та які розміщуються у його особистому кабінеті – є об’єктами авторського права, виключні права та умови на використання яких належить Виконавцю як власника веб-сайту, незалежно від території їх дії та факту реєстрації.
        </p>
        <p>
          8.3. Сторони прийшли до беззастережної згоди, що будь-які права інтелектуальної власності, в тому числі виключні майнові права інтелектуальної власності (виключне право на використання, виключне право дозволяти використання, право перешкоджати неправомірному використанню, в тому числі забороняти таке використання тощо) на будь-які матеріали (текстові, аудіо, відео, графічні та будь-які інші матеріали), доступ до яких Замовник отримав в зв’язку з одержанням ним Послуг (Товарів) за цим Договором, в повному обсязі належать Виконавцю та/або іншим правовласникам протягом всього терміну дії прав інтелектуальної власності.
        </p>
        <p>
          8.4. Замовнику забороняється копіювати, відтворювати, поширювати або будь-яким іншим чином використовувати в комерційних чи некомерційних цілях надану Виконавцем в рамках цього Договору інформацію і матеріали, створювати на її основі інформаційні продукти, а також використовувати цю інформацію будь-яким іншим чином, крім як для особистого користування.
        </p>
        <p>
          8.5. Послуги (Продукти) надаються для особистого використання Замовником. Забороняється передавати отриманий Замовником контент третім особам для їх спільного використання без спеціального на те дозволу Виконавця.
        </p>
        <p>
          8.6. Замовник розуміє і усвідомлює, що під час проведення Заходів може проводитися відео-зйомка та/або аудіо-запис тощо. Уклавши Договір Замовник дає дозвіл Виконавцю здійснювати таку фіксацію і використовувати її результати в будь-якій формі і будь-якими способами без узгодження з Замовником, в тому числі у всіх проектах та/або заходах, які організовуються та/або проводяться Виконавцем, як рекламний матеріал, передавати тертім особам, тощо.
        </p>
        <p>
          8.7. Будь-яке порушення прав інтелектуальної власності Виконавця переслідується у відповідності до чинного законодавства країни надання послуг та вимог міжнародного законодавства, що тягне за собою відповідну цивільну, адміністративну, кримінальну чи іншу визначену законом відповідальність.
        </p>
        <p>
          8.8. У разі виявлення фактів незаконного використання інтелектуальної власності Виконавця (використання бренду, копіювання або перетворення матеріалів курсу, або їх поширення), Виконавець має право на одностороннє припинення надання послуг і блокування відповідного користувача (аккаунту порушника), без відшкодування вартості раніше оплаченої Послуги (Товару).
        </p>
        <p>
          8.9. Виконавець, в свою чергу, має право за своїм вибором вимагати від порушника повного відшкодування збитків, заподіяних таким порушенням або ж виплати порушником компенсації в розмірі подвійної плати за правомірне користування об’єктами авторського права Виконавця за кожний окремий випадок такого неправомірного використання.
        </p>
        <p>
          8.10. У разі відмови порушника усунути виявлені порушення авторських прав Виконавцю в добровільному порядку (компенсація завданої шкоди), Виконавець отримує безумовне право на захист своїх порушених прав в примусовому порядку шляхом подання відповідного звернення (заяви) до правоохоронних органів та/або суду.
        </p>

        <h2>
          9. ВІДПОВІДАЛЬНІСТЬ СТОРІН
        </h2>
        <p>
          9.1. Сторони дійшли згоди, що вони зобов’язуються виконувати всі взяті на себе зобов’язання, що випливають у зв’язку із укладанням і виконанням даного Договору, сумлінно і добросовісно. У випадку невиконання чи неналежного виконання (виконання з порушенням умов Договору) своїх зобов’язань за цим Договором, Сторони несуть відповідальність, передбаченому як самим Договором, так і чинним законодавством.
        </p>
        <p>
          9.2. Виконавець докладає всіх розумних зусиль для того, щоб вся інформація на Сайті була точною, достовірною та актуальною. Виконавець не гарантує точність і актуальність будь-якої інформації, розміщеної на Сайті в кожен момент часу, і не приймає претензій щодо будь-яких збоїв і неточностей, а також будь-якого збитку, що є наслідком даних збоїв і неточностей.
        </p>
        <p>
          9.3. Виконавець не несе відповідальності перед Замовниками і третіми особами за часткове або повне невиконання ним зобов’язань, пов’язане із обставинами непереборної сили, тобто будь-якими надзвичайними і невідворотними обставинами, що не підлягають контролю Виконавця. До таких обставин можуть відноситися, зокрема, будь-які стихійні лиха, епідемії, епізоотії, природні та промислові катастрофи; терористичні акти; військові дії; порушень громадського порядку; прийняття органами державної влади або органами місцевого самоврядування актів, що містять заборони або обмеження щодо діяльності Виконавця; інші обставини, які не можуть бути заздалегідь передбачені Виконавцем, у тому числі вихід з ладу серверного обладнання (з вини організації, що надає послуги хостингу або інших осіб), збоїв в телекомунікаційних або енергетичних мережах, неправомірні дії третіх осіб, наслідки дії шкідливих програм, тощо.
        </p>
        <p>
          9.4. Замовник розуміє і приймає умови, згідно з яким порушення ним умов цього Договору може призвести до його розірвання Виконавцем в односторонньому порядку з наступним припиненням надання Замовнику послуг.
        </p>
        <p>
          9.5. У разі неотримання Замовником послуг не з прямої вини Виконавця, відсторонення Замовника від надання Послуг у зв’язку із порушенням Договору, відмови Замовника від цього Договору та/або послуг через будь-які суб’єктивні причини, тощо, сплачені Замовником за відповідний пакет Послуг (Товарів) кошти, – не повертаються.
        </p>
        <p>
          9.6. Виконавець не відшкодовує Замовнику збитки та/або компенсацію (включаючи упущену вигоду, тощо), понесені ним у період ненадання Послуг Виконавцем з причин, що не залежать від останнього.
        </p>
        <p>
          9.7. У разі, якщо з якої-небудь причини Виконавцем не було надано Послуг, зокрема, запланованого в певний термін Заходу, відповідальність Виконавця обмежується виключно наданням Послуги (проведенням відповідного Заходу) в інший термін.
        </p>

        <h2>
          10. СТРОК ДІЇ ДОГОВОРУ ТА ВНЕСЕННЯ ЗМІН ДО ДОГОВОРУ
        </h2>
        <p>
          10.1. Оферта діє безстроково. Даний Договір починає діяти з моменту акцепту Замовником цієї Оферти, на передбачених цим Договором умовах та діє до повного виконання Сторонами своїх зобов’язань за цим Договором (у тому числі відшкодування всіх завданих збитків та відповідних штрафних санкцій, компенсацій).
        </p>
        <p>
          10.2. Зміни та доповнення до даного Договору можуть бути зроблені Виконавцем в односторонньому порядку, без попереднього повідомлення про такі зміни Замовника. До правовідносин, що виникли до внесення змін і доповнень до даного Договору, застосовуються положення (редакція) Договору, яка була чинною (діяла) на момент виникнення таких правовідносин. Замовник не має права вносити зміни та доповнення до даного Договору.
        </p>
        <p>
          10.3. Внесені зміни до цього Договору, що набрали чинності, тобто з дня їх оприлюднення (публікації) на офіційному веб-сайті Виконавця, розповсюджуються на всіх Замовників одночасно.
        </p>
        <p>
          10.4. Даний Договір поширює свою дію на Замовників, які є користувачами Послуг (Товарів) Виконавця як до дати опублікування даного Договору, так і після дати його опублікування на офіційному веб-сайті Виконавця.
        </p>
        <p>
          10.5. Припинення дії Договору, тобто припинення надання Послуг, Виконавцем проводиться автоматична деактивація акаунту (особистого кабінету) о 24 годині 00 хвилин останнього дня оплаченого строку.
        </p>
        <p>
          10.6. Пролонгація дії Договору на наступний строк, відбувається за умови здійснення Замовником оплати вартості Послуг (Товарів) Виконавця, у відповідності до умов даного Договору.
        </p>

        <h2>
          11. УМОВИ КОНФІДЕНЦІЙНОСТІ
        </h2>
        <p>
          11.1. Будь-яка інформація, отримана Сторонами у зв’язку із виконанням даного Договору, у тому числі отримана однією Стороною від іншої Сторони, є конфіденційною інформацією (комерційна таємниця). Сторони зобов’язуються забезпечити належного та максимального контролю за дотриманням умов конфіденційності, у тому числі третіми особами, що будь-яким чином і у будь-який спосіб стали учасниками виниклих між Виконавцем і Замовником правовідносин за цим Договором.
        </p>
        <p>
          11.2. Сторони погоджуються не розкривати тане розголошувати повністю та/або частково інформацію, що є конфіденційною, а також не передавати інформацію з питань, пов’язаних із виконанням даного Договору, без відповідного дозволу (згоди) іншої Сторони, за винятком випадків надання інформації уповноваженим органам влади та у випадках, визначених чинним законодавством, а також за винятком випадків, коли розкриття інформації витікає із суті доручення Замовника за цим Договором, при цьому розкриття здійснення лише в обсязі, необхідному для виконання цього Договору.
        </p>

        <h2>
          12. ПРИКІНЦЕВІ ТА ЗАКЛЮЧНІ ПОЛОЖЕННЯ
        </h2>
        <p>
          12.1. Замовник гарантує, що всі умови цього Договору йому зрозумілі і він їх приймає безумовно і в повному обсязі.
        </p>
        <p>
          12.2. Сторони дійшли згоди, що всі суперечки і спори, що можуть виникати в процесі виконання даного Договору, у тому числі щодо надання Послуг Виконавцем, врегульовуються Сторонами шляхом здійснення відповідних переговорів та консультацій.
        </p>
        <p>
          12.3. У разі ж неможливості досягти вирішення спору шляхом переговорів та консультацій (недосягнення компромісу), Замовник залишає за собою право на пред’явлення відповідних письмових претензій, які обов’язково повинні бути розглянуті Виконавцем у встановлені чинним законодавством строки і порядку, з метою врегулювання наявних суперечок в досудовому порядку.
        </p>
        <p>
          12.4. У разі недосягнення згоди між Сторонами шляхом проведення відповідних переговорів та консультацій і неможливості врегулювання спору в досудовому порядку, такі спори та/або суперечки можуть бути вирішені в судовому порядку відповідно до вимог чинного законодавства України.
        </p>
        <p>
          12.5. Питання, що не було врегульовано цим Договором, у тому числі і визначення понять і термінів, підлягають тлумаченню відповідно до положень чинного законодавства України.
        </p>
        <p>
          12.6. Даний Договір регулюється положеннями чинного законодавства України. Визнання будь-якого положення або пункту даного Договору чи додатків до нього недійсними (нікчемними), не впливають на дійсність решти положень та умов даного Договору.
        </p>

        <h2>
          13. РЕКВІЗИТИ ВИКОНАВЦЯ:
        </h2>
        <p>
          ФОП Тацюн І.В. зареєстрована як суб’єкт господарювання за законодавством України та є платником податків ІІІ групи, ЄДРПОУ – 3657607887.
        </p>
        <p>
          Офіційний веб-сайт Інтернет-магазину – https://lovenbread.com.ua/
        </p>
        <p>
          Тел: +380733246550
        </p>
        <p>
          lovenbread.info@gmail.com
        </p>
      </Rules>
    </>
  );
}

export default OfferPage;