import React from "react";
import style from './Included.module.css';
import { useTranslation } from "react-i18next";
import FeatureItem from "../FeatureItem/FeatureItem";
import Container from "../Container/Container";
import SectionTitle from "../SectionTitle/SectionTitle";

function Included () {
  const [t] = useTranslation('common');
  const translationsData = t('included.items', { returnObjects: true });
  const itemsData = translationsData.map((item) => {
    return {
      ...item,
      icon: require(`../../assets/icons/included/${item.icon}.svg`),
    };
  });

  const items = itemsData.map((item, index) => {
    return <FeatureItem data={item} white key={`included-item-${index}`}/>
  });

  return (
    <section className={style.included}>
      <Container column className={style.included__container}>
        <SectionTitle secondary center className={style.included__title}>
          {t('included.title')}
        </SectionTitle>
        <div className={style.included__grid}>
          {items.length > 0 && items}
        </div>
      </Container>
    </section>
  );
}

export default Included;