import React from "react";
import style from './Button.module.css';
import {Link} from "react-router-dom";
import classNames from "classnames";

function Button ({
  children, 
  large = false,
  center = false,
  simple = false,
  maxContent = false, 
  className = false, 
  to = false,
  target = "_blank", 
  onClick,
  download = false,
}) {
  const classList = classNames(style.btn, {
    [style.btnLarge]: large,
    [style.btnMaxContent]: maxContent,
    [style.btnCenter]: center,
    [className]: className,
    [style.btnSimple]: simple,
  });

  if(to) {
    return (
      <Link className={classList} to={to} target={target} download={download}>
        {children}
      </Link>
    );
  }
  return (
    <button className={classList} onClick={onClick}>
      {children}
    </button>
  );
}

export default Button;