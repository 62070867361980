import React, {Suspense, useEffect} from 'react';
import style from './App.module.css';
import {Outlet} from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

function App() {
  const helmetContext = {};
  const {i18n} = useTranslation('common');
  const currentLang = useSelector(state => state.user.currentLang);

  useEffect(() => {
    i18n.changeLanguage(currentLang);
  },[i18n,currentLang]);
  

  return (
    <HelmetProvider context={helmetContext}>
      <Suspense fallback="loading">
        <>
          <main className={style.main}>
            <Outlet/>
          </main>
        </>
      </Suspense>
    </HelmetProvider>
  );
}

export default App;
