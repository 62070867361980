import React from "react";
import style from './SectionTitle.module.css';
import classNames from "classnames";

function SectionTitle ({
  children,
  className = false,
  secondary = false,
  center = false,
  large = false,
  tag = "h2"
}) {
  const classList = classNames(style.title, {
    [style.titleSecondary]: secondary,
    [style.titleCenter]: center,
    [style.titleLarge]: large,
    [className]: className
  })
  const CustomTag = `${tag}`
  return (
    <CustomTag className={classList}>
      {children}
    </CustomTag>
  );
}

export default SectionTitle;