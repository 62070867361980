import React from "react";
import style from './FeatureItem.module.css';
import classNames from "classnames";

function FeatureItem ({data, white = false}) {
  const {title, desc, icon} = data;
  
  const classList = classNames(style.featureItem, {
    [style.featureItemWhite]: white,
  });

  return (
    <div className={classList}>
      <img src={icon} alt={`${title} icon`} />
      <h3>
        {title}
      </h3>
      <p>
        {desc}
      </p>
    </div>
  );
}

export default FeatureItem;