import React from "react";
import style from './Reviews.module.css';
import { useTranslation } from "react-i18next";
import Container from "../Container/Container";
import SectionTitle from "../SectionTitle/SectionTitle";
import ReviewsItem from "./ReviewsItem/ReviewsItem";

function Reviews () {
  const [t] = useTranslation('common');
  const itemsData = t('reviews.items', { returnObjects: true });
  const items = itemsData.map((item, index) => {
    return <ReviewsItem data={item} key={`reviews-item-${index}`}/>
  });

  return (
    <section className={style.reviews}>
      <Container middle column relative className={style.reviews__container}>
        <SectionTitle  center className={style.reviews__title}>
          {t('reviews.title')}
        </SectionTitle>
        <div className={style.reviews__grid}>
          {items.length && items}
        </div>
      </Container>
    </section>
  );
}

export default Reviews;