import React from "react";
import style from './About.module.css';
import Container from "../Container/Container";
import { useTranslation } from "react-i18next";
import authorImg from "../../assets/about-bg.jpg";

function About () {
  const [t] = useTranslation('common');
  const translationsData = t('about.quote', { returnObjects: true });
  const quoteContent = translationsData.map((text, index) => {
    return <p key={`about-text-${index}`}>{text}</p>;
  });
  
  return (
    <section className={style.about}>
      <Container relative className={style.about__container}>
        <blockquote className={style.about__quote}>
          {quoteContent.length > 0 && quoteContent}
        </blockquote>
      </Container>
      <img className={style.about__bg} src={authorImg} alt="Author of course" />
    </section>
  );
}

export default About;