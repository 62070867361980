import React from "react";
import style from './ForWhom.module.css';
import { useTranslation } from "react-i18next";
import FeatureItem from "../FeatureItem/FeatureItem";
import Container from "../Container/Container";
import SectionTitle from "../SectionTitle/SectionTitle";
import SectionDesc from "../SectionDesc/SectionDesc";

function ForWhom () {
  const [t] = useTranslation('common');
  const translationsData = t('forwhom.items', { returnObjects: true });
  const itemsData = translationsData.map((item) => {
    return {
      ...item,
      icon: require(`../../assets/icons/forwhom/${item.icon}.svg`),
    };
  });

  const items = itemsData.map((item, index) => {
    return <FeatureItem data={item} key={`for-whom-item-${index}`}/>
  });

  return (
    <section className={style.forWhom}>
      <Container column className={style.forWhom__container}>
        <SectionTitle center className={style.forWhom__title}>
          {t('forwhom.title')}
        </SectionTitle>
        <SectionDesc center className={style.forWhom__desc}>
          {t('forwhom.desc')}
        </SectionDesc>
        <div className={style.forWhom__grid}>
          {items.length > 0 && items}
        </div>
      </Container>
    </section>
  );
}

export default ForWhom;