import React from "react";
import SystemAlert from "../../components/SystemAlert/SystemAlert";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import SectionDesc from "../../components/SectionDesc/SectionDesc";
import Button from "../../components/Button/Button";
import Header from "../../components/Header/Header";
import logo from "../../assets/logo.png";
import { useTranslation } from "react-i18next";

function DeclinedPage () {
  const [t] = useTranslation('common');
  return (
    <>
      <Header/>
      <SystemAlert>
        <img src={logo} alt="lovenbread logo" />
        <SectionTitle center tag="h1">
          {t('declined.title')}
        </SectionTitle>
        <SectionDesc center>
          {t('declined.desc')}
        </SectionDesc>
        <Button to={process.env.REACT_APP_INST_LINK}>
          {t('declined.link')}
        </Button>
      </SystemAlert>
    </>
  );
}

export default DeclinedPage;