import React from "react";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import logo from "../../assets/logo.png";
import Rules from "../../components/Rules/Rules";

function PolicyPage() {
  return (
    <>
      <Rules>
        <img src={logo} alt="lovenbread logo" />
        <SectionTitle center tag="h1">
          Полiтика конфiденцiйностi
        </SectionTitle>
        <h2>
          1. Загальні положення:
        </h2>
        <p>
          Ця політика обробки персональних даних складена відповідно до вимог Закону від 27.07.2006. №152-З «Про персональні дані» та визначає порядок обробки персональних даних та заходи щодо забезпечення безпеки персональних даних Тацюн Іванна Віталіївна (далі – Оператор).
        </p>
        <p>
          Оператор ставить своєю найважливішою метою та умовою здійснення своєї діяльності дотримання прав та свобод людини та громадянина при обробці його персональних даних, у тому числі захисту прав на недоторканність приватного життя, особисту та сімейну таємницю.
        </p>
        <p>
          Ця політика Оператора щодо обробки персональних даних (далі – Політика) застосовується до всієї інформації, яку Оператор може отримати про відвідувачів веб-сайту https://lovenbread.com.ua/.
        </p>

        <h2>
          2. Основні поняття, що використовуються у Політиці:
        </h2>
        <p>
          Автоматизована обробка персональних даних – обробка персональних даних за допомогою засобів обчислювальної техніки;
        </p>
        <p>
          Блокування персональних даних – тимчасове припинення обробки персональних даних (крім випадків, якщо обробка необхідна уточнення персональних даних);
        </p>
        <p>
          Веб-сайт – сукупність графічних та інформаційних матеріалів, а також програм для ЕОМ та баз даних, що забезпечують їх доступність у мережі інтернет за мережевою адресою https://lovenbread.com.ua/;
        </p>
        <p>
          Інформаційна система персональних даних - сукупність персональних даних, що містяться в базах даних, і забезпечують їх обробку інформаційних технологій та технічних засобів;
        </p>
        <p>
          Знеособлення персональних даних — дії, у яких неможливо визначити без використання додаткової інформації належність персональних даних конкретному Користувачеві чи іншому суб'єкту персональних даних;
        </p>
        <p>
          Обробка персональних даних – будь-яка дія (операція) або сукупність дій (операцій), що здійснюються з використанням засобів автоматизації або без використання таких засобів з персональними даними, включаючи збирання, запис, систематизацію, накопичення, зберігання, уточнення (оновлення, зміну), вилучення, використання, передачу (розповсюдження, надання, доступ), знеособлення, блокування, видалення, знищення персональних даних;
        </p>
        <p>
          Оператор – державний орган, муніципальний орган, юридична або фізична особа, що самостійно або спільно з іншими особами організовують та (або) здійснюють обробку персональних даних, а також визначають цілі обробки персональних даних, склад персональних даних, що підлягають обробці, дії (операції), що здійснюються із персональними даними;
        </p>
        <p>
          Персональні дані – будь-яка інформація, що стосується прямо або опосередковано до певного або визначеного Користувачеві веб-сайту https://lovenbread.com.ua/
        </p>
        <p>
          Користувач – будь-який відвідувач сайту https://lovenbread.com.ua/
          Надання персональних даних – дії, створені задля розкриття персональних даних певному особі чи певному колу осіб;
        </p>
        <p>
          Поширення персональних даних – будь-які дії, спрямовані на розкриття персональних даних невизначеному колу осіб (передача персональних даних) або на ознайомлення з персональними даними необмеженого кола осіб, зокрема оприлюднення персональних даних у засобах масової інформації, розміщення в інформаційно-телекомунікаційних мережах або надання доступу до персональних даних будь-яким іншим способом;
        </p>
        <p>
          Транскордонна передача персональних даних – передача персональних даних на територію іноземної держави до органу влади іноземної держави, іноземної фізичної або іноземної юридичної особи;
        </p>
        <p>
          Знищення персональних даних – будь-які дії, внаслідок яких персональні дані знищуються безповоротно з неможливістю подальшого відновлення змісту персональних даних в інформаційній системі персональних даних та (або) внаслідок яких знищуються матеріальні носії персональних даних.
        </p>

        <h2>
          3. Оператор може обробляти такі персональні дані Користувача
        </h2>
        <p>
          Прізвище ім'я по батькові;
        </p>
        <p>
          Електронна адреса;
        </p>
        <p>
          Номер телефону;
        </p>
        <p>
          Також на сайті відбувається збір та обробка знеособлених даних про відвідувачів (в т.ч. файлів cookie) за допомогою сервісів інтернет-статистики (Google Аналітика та інших).
        </p>
        <p>
          Дані за текстом Політики об'єднані загальним поняттям Персональні дані.
        </p>

        <h2>
          4. Цілі обробки персональних даних
        </h2>
        <p>
          Мета обробки персональних даних Користувача – інформування Користувача за допомогою надсилання електронних листів; надання доступу Користувачу до сервісів, інформації та/або матеріалів, що містяться на веб-сайті.
        </p>
        <p>
          Також Оператор має право надсилати Користувачеві повідомлення про нові продукти та послуги, спеціальні пропозиції та різні події. Користувач завжди може відмовитися від отримання інформаційних повідомлень, надіславши Оператору лист на адресу електронної пошти з позначкою «Відмова від повідомлень про нові продукти та послуги та спеціальні пропозиції».
        </p>
        <p>
          Знеособлені дані Користувачів, які збираються за допомогою сервісів інтернет-статистики, служать для збору інформації про дії Користувачів на сайті, покращення якості сайту та його змісту.
        </p>

        <h2>
          5. Правові основи обробки персональних даних
        </h2>
        <p>
          Оператор обробляє персональні дані Користувача лише у разі їх заповнення та/або відправлення Користувачем самостійно через спеціальні форми, розташовані на сайті https://lovenbread.com.ua/
        </p>
        <p>
          Заповнюючи відповідні форми та/або надсилаючи свої персональні дані Оператору, Користувач висловлює свою згоду з цією Політикою.
        </p>
        <p>
          Оператор обробляє знеособлені дані про Користувача у випадку, якщо це дозволено в налаштуваннях браузера Користувача (включено збереження файлів cookie та використання технології JavaScript).
        </p>

        <h2>
          6. Порядок збирання, зберігання, передачі та інших видів обробки персональних даних
        </h2>
        <p>
          Безпека персональних даних, що обробляються Оператором, забезпечується шляхом реалізації правових, організаційних та технічних заходів, необхідних для виконання у повному обсязі вимог чинного законодавства у сфері захисту персональних даних.
        </p>
        <p>
          Оператор забезпечує збереження персональних даних та вживає всіх можливих заходів, що виключають доступ до персональних даних неуповноважених осіб.
        </p>
        <p>
          Персональні дані Користувача ніколи, за жодних умов не будуть передані третім особам, за винятком випадків, пов'язаних із виконанням чинного законодавства.
        </p>
        <p>
          У разі виявлення неточностей у персональних даних, Користувач може актуалізувати їх самостійно шляхом надсилання Оператору повідомлення на адресу електронної пошти Оператора i з позначкою «Актуалізація персональних даних».
        </p>
        <p>
          Термін обробки персональних даних є необмеженим. Користувач може будь-якої миті відкликати свою згоду на обробку персональних даних, надіславши Оператору повідомлення за допомогою електронної пошти на електронну адресу Оператора lovenbread.info@gmail.com з позначкою «Відкликання згоди на обробку персональних даних».
        </p>

        <h2>
          7. Транскордонна передача персональних даних
        </h2>
        <p>
          Оператор на початок здійснення транскордонної передачі персональних даних зобов'язаний переконатися у цьому, що іноземним державою, територію якого передбачається здійснювати передачу персональних даних, забезпечується надійний захист прав суб'єктів персональних даних.
        </p>
        <p>
          Транскордонна передача персональних даних на території іноземних держав, що не відповідають вищезазначеним вимогам, може здійснюватися лише у разі наявності згоди у письмовій формі суб'єкта персональних даних на транскордонну передачу його персональних даних та/або виконання договору, стороною якого є суб'єкт персональних даних.
        </p>

        <h2>
          8. Заключні положення
        </h2>
        <p>
          Користувач може отримати будь-які роз'яснення щодо питань, що цікавляться обробкою його персональних даних, звернувшись до Оператора за допомогою електронної пошти lovenbread.info@gmail.com.
        </p>
        <p>
          У цьому документі буде відображено будь-які зміни політики обробки персональних даних Оператором. Політика діє безстроково до заміни її новою версією.
        </p>
        <p>
          Актуальна версія Політики у вільному доступі розташована в Інтернеті за адресою https://lovenbread.com.ua/policy.
        </p>

        <h2>
          9. Реквізити оператора
        </h2>
        <p>
          Фізична особа-підприємець – ФОП Тацюн І.В. зареєстрована як суб’єкт господарювання за законодавством України та є платником податків ІІІ групи, ЄДРПОУ: 3657607887.
        </p>
        <p>
          Oфіційний веб-сайт Інтернет-магазину – https://lovenbread.com.ua/
        </p>
        <p>
          Тел: +380733246550
        </p>
        <p>
          lovenbread.info@gmail.com
        </p>
      </Rules>
    </>
  );
}

export default PolicyPage;