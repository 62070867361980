import React from "react";
import style from './LangSwitcher.module.css';
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { setCurrentLang } from "../../slices/userSlice";


function LangSwitcher () {
  const {i18n} = useTranslation('common');
  const dispatch = useDispatch();

  let locales = null;

  const handleLocaleClick = (lang) => {
    dispatch(setCurrentLang(lang));
  }

  const LOCALE_NAMES = {
    uk: 'ua',
    en: 'eng',
    ru: 'ru',
  }
  locales = ['uk','en','ru'].map((lang) => {
    const classList = classNames(style.langSwitch__item, {[style.langSwitch__itemActive]: lang === i18n.language});
    return (
      <button key={`locale-${lang}`} className={classList} onClick={() => {handleLocaleClick(lang)}}>
            {lang in LOCALE_NAMES ? LOCALE_NAMES[lang] : lang}
      </button>
    )
  })
  return (
    <div className={style.langSwitch}>
      {locales}
    </div>
  );
}

export default LangSwitcher;