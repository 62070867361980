import React from "react";
import style from './Footer.module.css';
import Container from "../Container/Container";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Social from "../Social/Social";

function Footer() {
  const [t] = useTranslation('common');
  return (
    <footer className={style.footer}>
      <Container className={style.footer__container}>
        <div className={classNames(style.footer__col, style.footer__colCredentials)}>
          <span>
            ФОП Тацюн І.В.
          </span>
          <span>
            ЄДРПОУ: 3657607887
          </span>
          <Link to="mailto:lovenbread.info@gmail.com">
            lovenbread.info@gmail.com
          </Link>
        </div>
        <div className={classNames(style.footer__col, style.footer__colSocial)}>
          <Social/>
          <span>
            © {new Date().getFullYear()} {t('footer.copyright')}
          </span>
        </div>
        <div className={classNames(style.footer__col, style.footer__colNav)}>
          <Link to="/policy">
            {t('footer.policy')}
          </Link>
          <Link to="/offer">
            {t('footer.offer')}
          </Link>
        </div>
      </Container>
    </footer>
  );
}

export default Footer;