import React, { useRef, useState } from "react";
import Flicking, { ViewportSlot } from "@egjs/react-flicking";
import { Arrow, Pagination, Sync } from "@egjs/flicking-plugins";
import "@egjs/react-flicking/dist/flicking.css";
import "@egjs/flicking-plugins/dist/pagination.css";
import "@egjs/flicking-plugins/dist/arrow.css";
import style from './BreadCarousel.module.css';
import "./flicker-custom.css";
import Modal from "react-modal";
import classNames from "classnames";
import Button from "../Button/Button";

function BreadCarousel() {
  const flickingThumb = useRef();
  const flickingFull = useRef();
  const thumbPlugins = [new Pagination({ type: 'bullet' })];
  const [fullPlugins, setFullPlugins] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currIndex, setCurrIndex] = useState(false);
  Modal.setAppElement('#root');

  const initFullPlugins = () => {
    setFullPlugins([
      new Sync({
        type: "index",
        synchronizedFlickingOptions: [
          {
            flicking: flickingFull.current,
            isSlidable: true,
            activeClass: "active"

          },
          {
            flicking: flickingThumb.current,
            isClickable: true,
          }
        ]
      }),
      new Arrow()
    ]);
  }

  const handleThumbClick = (index) => {
    setCurrIndex(index)
    setIsModalOpen(true);
    console.log(flickingFull.current);

  }

  const handleModalClose = () => {
    setIsModalOpen(false);
    setCurrIndex(flickingThumb.index);
  }

  const thumbItems = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, index) => {
    const loadedSrc = require(`../../assets/bread-carousel-thumb/${item}.jpg`);
    return <img draggable="false" onClick={() => handleThumbClick(index)} className={style.breadCarousel__item} src={loadedSrc} alt='Bread' key={`review-img-${item}`} />
  });

  const fullItems = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => {
    const loadedSrc = require(`../../assets/bread-carousel-full/${item}.jpg`);
    return <div className={style.breadCarousel__itemFull} key={`review-img-${item}`}>
      <img draggable="false" src={loadedSrc} alt='Bread' />
    </div>
  });

  return (
    <section className={style.breadCarousel}>
      <Flicking
        align="center"
        circular={true}
        ref={flickingThumb}
        plugins={thumbPlugins}
        moveType="freeScroll"
      >
        {thumbItems}
        <ViewportSlot>
          <div className="flicking-pagination"></div>
        </ViewportSlot>
      </Flicking>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={handleModalClose}
        className={classNames(style.carouselModal, "has-carousel")}
        overlayClassName={style.carouselModalOverlay}

      >
        <Button simple className={style.carouselModalClose} onClick={handleModalClose}>
          <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none">
            <path d="M20.7457 3.32851C20.3552 2.93798 19.722 2.93798 19.3315 3.32851L12.0371 10.6229L4.74275 3.32851C4.35223 2.93798 3.71906 2.93798 3.32854 3.32851C2.93801 3.71903 2.93801 4.3522 3.32854 4.74272L10.6229 12.0371L3.32856 19.3314C2.93803 19.722 2.93803 20.3551 3.32856 20.7457C3.71908 21.1362 4.35225 21.1362 4.74277 20.7457L12.0371 13.4513L19.3315 20.7457C19.722 21.1362 20.3552 21.1362 20.7457 20.7457C21.1362 20.3551 21.1362 19.722 20.7457 19.3315L13.4513 12.0371L20.7457 4.74272C21.1362 4.3522 21.1362 3.71903 20.7457 3.32851Z" fill="#0F0F0F" />
          </svg>
        </Button>
        <Flicking
          align="center"
          defaultIndex={currIndex}
          circular={true}
          onReady={initFullPlugins}
          ref={flickingFull}
          plugins={fullPlugins}
          panelsPerView={1}
        >
          {fullItems}
          <ViewportSlot>
            <span className="flicking-arrow-prev"></span>
            <span className="flicking-arrow-next"></span>
          </ViewportSlot>
        </Flicking>
      </Modal>
    </section>
  );
}

export default BreadCarousel;