import React from "react";
import style from './Hero.module.css';
import {useTranslation} from "react-i18next";
import Button from "../Button/Button";
import Container from "../Container/Container";
import SectionDesc from "../SectionDesc/SectionDesc";
import logo from "../../assets/logo.png";
import LangSwitcher from "../LangSwitcher/LangSwitcher";
import Social from "../Social/Social";
import leftBreadImg from "../../assets/left-bread.png";
import leftBreadImgM from "../../assets/left-bread-m.png";
import rightBreadImg from "../../assets/right-bread.png";
import rightBreadImgM from "../../assets/right-bread-m.png";
import { Helmet } from "react-helmet-async";

function Hero ({onMoreClick}) {
  const [t] = useTranslation('common');
  return (
    <section className={style.hero}>
      <Helmet>
        <link rel="preload" as="image" href={leftBreadImgM} />
        <link rel="preload" as="image" href={rightBreadImgM} />
      </Helmet>
      <picture>
        <source srcSet={leftBreadImgM} media="(max-width: 1200px)" />
        <img fetchpriority="high" className={style.hero__leftDeco} src={leftBreadImg} alt="Bread img" />
      </picture>
      
      <Container small relative column className={style.hero__container}>
        <div className={style.hero__header}>
          <LangSwitcher/>
          <Social small/>
        </div>
        <img className={style.hero__logo} src={logo} alt="lovenbread logo" />
        <h1 className={style.hero__title}>
          {t('hero.title')}
        </h1>
        <SectionDesc center big className={style.hero__desc}>
          {t('hero.desc')}
        </SectionDesc>
        <Button center maxContent className={style.hero__more} onClick={onMoreClick}>
          {t('hero.btn')}
        </Button>
      </Container>
      <picture>
        <source srcSet={rightBreadImgM} media="(max-width: 1200px)" />
        <img fetchpriority="high" className={style.hero__rightDeco} src={rightBreadImg} alt="Bread img" />
      </picture>
    </section>
  );
}

export default Hero;