import {createSlice} from "@reduxjs/toolkit";
const getInitialState = () => {
  const initial = {
    currentLang: 'uk',
  };
  const localKeys = ['currentLang'];
  localKeys.forEach((key) => {
    const localValue = localStorage.getItem(key);
    if(localValue) {
      initial[key] = localValue;
    }
  })
  return initial;
}

const initialState = getInitialState();

export const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    setCurrentLang: (state, action) => {
      const lang = action.payload;
      state.currentLang = lang;
      localStorage.setItem('currentLang', lang);
    },
  }
});

export const {setCurrentLang} = userSlice.actions;

export default userSlice.reducer;