import React from "react";
import style from './SectionDesc.module.css';
import classNames from "classnames";

function SectionDesc ({
  children,
  className = false,
  secondary = false,
  center = false,
  large = false,
  lastBold = false
}) {
  const classList = classNames(style.desc, {
    [className]: className,
    [style.descCenter]: center,
    [style.descLarge]: large,
    [style.descSecondary]: secondary,
    [style.descLastBold]: lastBold,
  });

  return (
    <p className={classList}>
      {children}
    </p>
  );
}

export default SectionDesc;