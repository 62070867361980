import React from "react";
import style from './Learn.module.css';
import bgImg from '../../assets/learn-bg.jpg';
import { useTranslation } from "react-i18next";
import Container from "../Container/Container";
import SectionTitle from "../SectionTitle/SectionTitle";
import LearnItem from "./LearnItem/LearnItem";

function Learn () {
  const [t] = useTranslation('common');
  const itemsData = t('learn.items', { returnObjects: true });
  const items = itemsData.map((item, index) => {
    return <LearnItem title={item} key={`learn-item-${index}`}/>
  });

  return (
    <section className={style.learn}>
      <Container className={style.learn__container}>
        <div className={style.learn__inner}>
          <SectionTitle className={style.learn__title}>
            {t('learn.title')}
          </SectionTitle>
          <ol className={style.learn__grid}>
            {items.length && items}
          </ol>
        </div>
        <img className={style.learn__img} src={bgImg} alt="" />
      </Container>
    </section>
  );
}

export default Learn;