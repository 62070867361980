import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import commonEn from "./translations/en/common.json";
import commonRu from "./translations/ru/common.json";
import commonUk from "./translations/uk/common.json";

const resources = {
  en: {
    common: commonEn,
  },
  ru: {
    common: commonRu,
  },
  uk: {
    common: commonUk,
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: ["uk","en","ru"],
    supportedLngs: ["en","uk","ru"],
    interpolation: {
      escapeValue: false,
    }
  });

export default i18n;