import React from "react";
import style from './Rules.module.css';
import Container from "../Container/Container";

function Rules ({children}) {
  return (
    <section className={style.rules}>
      <Container middle>
        {children}
      </Container>
    </section>
  );
}

export default Rules;