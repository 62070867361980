import React from "react";
import style from './Container.module.css';
import classNames from "classnames";

function Container ({
  children, 
  className = false, 
  small = false, 
  middle = false,
  column = false,
  relative = false
}) {
  const classList = classNames(style.container, {
    [style.containerSmall] : small,
    [style.containerMiddle] : middle,
    [style.containerColumn] : column,
    [style.containerRelative] : relative,
    [className] : className,
  });
  
  return (
    <div className={classList}>
      {children}
    </div>
  );
}

export default Container;